import { ChangeDetectionStrategy, Component, HostListener, Input } from '@angular/core';
import { CronInputComponent } from './cron-input.component';

@Component({
  selector: 'x-cron-input-toggle',
  template: `
    <fa-icon icon="history"></fa-icon>
  `,
  host: {
    class: 'x-cron-input-toggle',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CronInputToggleComponent {
  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    if (this.for) {
      console.log('open');
      this.for.open();
      event.preventDefault();
      event.stopPropagation();
    } else {
      console.warn('CronInputToggleComponent no CronInputComponent connected');
    }
  }

  @Input()
  for: CronInputComponent | null;
}
