import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

interface TimeOption {
  label: string;
  value: string;
}

const DAY_LABELS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const MONTH_LABELS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const TIME_OPTIONS = Array.from({ length: 24 }, (v, k) => k).reduce((options, h) => {
  Array.from({ length: 4 }, (v, k) => k * 15).forEach((m) => {
    const value = String(h).padStart(2, '0') + ':' + String(m).padStart(2, '0');
    options.push({ label: value, value });
  });

  return options;
}, [] as TimeOption[]);

const DOW_OPTIONS = Array.from({ length: 7 }, (v, k) => k).map((i) => {
  return {
    label: DAY_LABELS[i],
    value: String(i + 1),
  };
});

const DOM_LABELS = ['1st', '2nd', '3rd', '4th'];

const DOM_OPTIONS = Array.from({ length: 31 }, (v, k) => k).map((i) => {
  return {
    label: DOM_LABELS[i] ?? `${i + 1}th`,
    value: String(i + 1),
  };
});

const TYPE_OPTIONS = [
  {
    value: 'weekdays',
    label: 'weekday(s)',
  },
  {
    value: 'month',
    label: 'month(s)',
  },
  {
    value: 'days_of_month',
    label: 'day(s) of month',
  },
];

const MONTH_OPTIONS = Array.from({ length: 12 }, (v, k) => k).map((i) => {
  return {
    label: MONTH_LABELS[i],
    value: String(i + 1),
  };
});

export class CronFormGroup extends UntypedFormGroup {
  typeOptions = TYPE_OPTIONS;
  timeOptions = TIME_OPTIONS;
  dowOptions = DOW_OPTIONS;
  domOptions = DOM_OPTIONS;
  monthOptions = MONTH_OPTIONS;

  constructor() {
    super({
      time: new UntypedFormControl('00:00'),
      daysOfMonth: new UntypedFormControl([]),
      month: new UntypedFormControl([]),
      daysOfWeek: new UntypedFormControl([]),
      type: new UntypedFormControl('weekdays'),
    });
  }

  setStringValue(cronString: null | string, options?: { emitEvent?: boolean }) {
    if (!cronString) {
      this.reset();
      return;
    }

    const [minutes, hours, dom, month, dow] = cronString.split(' ');
    let type = 'weekdays';

    if (dow !== '*') {
      type = 'weekdays';
    } else if (dom !== '*') {
      type = 'days_of_month';
    } else if (month !== '*') {
      type = 'month';
    }

    this.setValue(
      {
        type,
        time: `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`,
        daysOfMonth: dom === '*' ? [] : dom.split(','),
        month: month === '*' ? [] : month.split(','),
        daysOfWeek: dow === '*' ? [] : dow.split(','),
      },
      options,
    );
  }

  getStringValue(): string {
    const { type, time, daysOfMonth, month, daysOfWeek } = this.value;

    const [h, m] = time.split(':');
    const timePart = `${parseInt(m)} ${parseInt(h)}`;

    switch (type) {
      case 'days_of_month':
        return `${timePart} ${daysOfMonth.length ? daysOfMonth.join(',') : '*'} * *`;
      case 'month':
        return `${timePart} 1 ${month.length ? month.join(',') : '*'} *`;
      default:
        return `${timePart} * * ${daysOfWeek.length ? daysOfWeek.join(',') : '*'}`;
    }
  }
}
