import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { CronFormGroup } from '../../cron.formgroup';

@Component({
  selector: 'x-cron-input',
  templateUrl: './cron-input.component.html',
  styleUrls: ['./cron-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: CronInputComponent }],
  host: {
    class: 'x-cron-input',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CronInputComponent
  implements ControlValueAccessor, OnInit, OnDestroy, MatFormFieldControl<string>
{
  static nextId = 0;

  @HostBinding()
  id = `x-cron-input-${CronInputComponent.nextId++}`;
  controlType = 'x-cron-input';

  private _destroy$ = new Subject<void>();
  private _onTouched: any = () => {};
  private _onChange: any = () => {};

  @Input()
  set value(value: string | null) {
    this.writeValue(value);
  }
  get value() {
    return this._value;
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  _placeholder: string;

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.control.disable() : this.control.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  get empty() {
    return !this.value;
  }
  get errorState(): boolean {
    return (this.control.invalid || !!this.ngControl?.control?.invalid) && this.control.touched;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty || this.isOpen;
  }

  @ViewChild(CdkConnectedOverlay)
  overlay: CdkConnectedOverlay;

  focused = false;
  stateChanges = new Subject<void>();

  isOpen = false;
  control = new CronFormGroup();
  _value: string | null;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private changeRef: ChangeDetectorRef,
  ) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.control.valueChanges
      .pipe(
        tap((v) => {
          this._value = this.control.getStringValue();
          console.log('cron', this._value);
          this._onChange(this._value);
          this._onTouched();
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  writeValue(string: any): void {
    this._value = string;
    this.control.setStringValue(string, { emitEvent: false });
    this.stateChanges.next();
    this.changeRef.markForCheck();
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this.open();
    }
  }

  setDescribedByIds(ids: string[]) {}

  clear() {
    this.control.reset(null);
    this.control.markAsUntouched();
  }

  onAttachOverlay() {
    console.log('onAttachOverlay', this.overlay);
  }

  open() {
    this.isOpen = true;
    this.changeRef.markForCheck();
    this.stateChanges.next();
  }

  close() {
    this.isOpen = false;
    this.changeRef.markForCheck();
    this.stateChanges.next();
  }

  trackByValue = (i: number, o: any) => o.value;
}
