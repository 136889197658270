<span class="cron-label" cdkOverlayOrigin (click)="open()" #trigger="cdkOverlayOrigin" tabindex="0">
  {{ (_value | cron) ?? _placeholder }}
</span>
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayDisableClose]="false"
  [cdkConnectedOverlayFlexibleDimensions]="true"
  [cdkConnectedOverlayGrowAfterOpen]="false"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'x-cron-overlay-backdrop'"
  (backdropClick)="close()"
  (attach)="onAttachOverlay()"
>
  <div
    class="x-cron-input-overlay"
    [formGroup]="control"
    cdkTrapFocus
    [cdkTrapFocusAutoCapture]="true"
  >
    <span class="mat-hint">At</span>
    <mat-select formControlName="time" style="min-width: 60px" tabindex="-1">
      <mat-option *ngFor="let time of control.timeOptions" [value]="time.value">
        {{ time.label }}
      </mat-option>
    </mat-select>
    <span class="mat-hint">every</span>

    <mat-select formControlName="type" style="min-width: 140px">
      <mat-option
        *ngFor="let option of control.typeOptions; trackBy: trackByValue"
        [value]="option.value"
      >
        {{ option.label }}
      </mat-option>
    </mat-select>

    <span class="mat-hint">on</span>
    <ng-container [ngSwitch]="control.value.type">
      <ng-template ngSwitchDefault>
        <mat-select
          [multiple]="true"
          formControlName="daysOfWeek"
          style="min-width: 150px"
          placeholder="any weekday"
        >
          <mat-option *ngFor="let day of control.dowOptions; let index = index" [value]="day.value">
            {{ day.label }}
          </mat-option>
        </mat-select>
      </ng-template>
      <ng-template ngSwitchCase="month">
        <mat-select
          [multiple]="true"
          formControlName="month"
          style="min-width: 150px"
          placeholder="any month"
        >
          <mat-option
            *ngFor="let month of control.monthOptions; let index = index"
            [value]="month.value"
          >
            {{ month.label }}
          </mat-option>
        </mat-select>
      </ng-template>
      <ng-template ngSwitchCase="days_of_month">
        <mat-select
          [multiple]="true"
          formControlName="daysOfMonth"
          style="min-width: 150px"
          placeholder="any day"
        >
          <mat-option *ngFor="let day of control.domOptions; let index = index" [value]="day.value">
            {{ day.label }}
          </mat-option>
        </mat-select>
      </ng-template>
    </ng-container>
  </div>
</ng-template>
