import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { IconModule } from '@x/common/icon';
import { CronInputToggleComponent } from './components/cron-input/cron-input-toggle.component';
import { CronInputComponent } from './components/cron-input/cron-input.component';
import { CronPipe } from './pipes/cron.pipe';
@NgModule({
  declarations: [CronInputComponent, CronInputToggleComponent, CronPipe],
  providers: [],
  imports: [
    CommonModule,
    IconModule,
    ReactiveFormsModule,
    MatSelectModule,
    OverlayModule,
    MatRadioModule,
    A11yModule,
  ],
  exports: [CronInputComponent, CronPipe, CronInputToggleComponent],
})
export class CronModule {}
