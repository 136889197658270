import { Pipe, PipeTransform } from '@angular/core';
import cronstrue from 'cronstrue';

@Pipe({
  name: 'cron',
})
export class CronPipe implements PipeTransform {
  transform(expression: any) {
    if (typeof expression === 'string') {
      return cronstrue.toString(expression, { use24HourTimeFormat: true });
    }
    return null;
  }
}
